<template>
  <div>
    <div class="select-card">
      <div class="select-card-top">
        <div style="padding-bottom: 10px">导出数据维度</div>
        <el-date-picker
          v-model="value1"
          type="daterange"
          size="small"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="onChangeDate"
          value-format="yyyyMMdd"
        >
        </el-date-picker>
        <el-button
          style="margin-left: 10px"
          size="small"
          type="primary"
          @click="downloadData"
          >导出</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: [],
      params: {
        endDate: "",
        startDate: "",
      },
    };
  },
  methods: {
    onChangeDate(val) {
      console.log("val", val);
      if (!val || this.value1.length === 0) {
        this.params.startDate = "";
        this.params.endDate = "";
      }
      this.params.startDate = this.value1[0];
      this.params.endDate = this.value1[1];
    },
    downloadData() {
      if (!this.params.startDate) {
        this.$message.error("请先选择日期");
        return;
      }
      this.axios.get("EXPORTORDERINFO", this.params).then((result) => {
        console.log("result instanceof Blob", result);
        if (result instanceof Blob) {
          const blob = new Blob([result]);
          console.log(blob);
          const fileName = "订单数据" + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        } else {
          this.$message.error("导出失败！");
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  .con {
    flex: 1;
  }
  .cardCon {
    // padding: 24px;
    // box-sizing: border-box;

    // display: flex;
    margin-top: 44px;
    .title {
      font-size: 14px;
      padding: 16px 0;
      box-sizing: border-box;
    }
    .buttonCon {
      display: flex;
      padding: 0 0;
      box-sizing: border-box;
    }
  }
  .cardCon:first-child {
    margin-top: 0;
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 30px 24px 30px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
